var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "statistik"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_vm.paketAktif[0] == null ? _c('Blank') : _c('Tryout', {
    attrs: {
      "data": _vm.paketAktif
    },
    on: {
      "loadKategori": function loadKategori(value) {
        return _vm.handleFilterKategori(value);
      },
      "load": function load(value) {
        return _vm.handleFilterSearch(value);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }